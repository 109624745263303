export const PROJECT_DUPLICATES_BAHAVIOR__IGNORE = 'Ignore';
export const PROJECT_DUPLICATES_BAHAVIOR__OVERWRITE = 'Overwrite';

export const PROJECT_DUPLICATES_BAHAVIOR_OPTIONS = [
  {
    value: PROJECT_DUPLICATES_BAHAVIOR__IGNORE,
    label: 'Ignore',
  },
  {
    value: PROJECT_DUPLICATES_BAHAVIOR__OVERWRITE,
    label: 'Overwrite',
  },
];

export const PROJECT_TYPES = [];

export const PROJECT_DUPLICATES_BAHAVIORS = [
  PROJECT_DUPLICATES_BAHAVIOR__IGNORE,
  PROJECT_DUPLICATES_BAHAVIOR__OVERWRITE,
];

export const PROJECT_CHART_TYPE__TOTAL_SCORE = 'totalScore';
export const PROJECT_CHART_TYPE__TOTAL_SCORE_INDIVIDUAL =
  'totalScoreIndividual';
export const PROJECT_CHART_TYPE__TOTAL_SCORE_NO_MILESTONES =
  'totalScoreNoMilestones';
export const PROJECT_CHART_TYPE__TOTAL_SCORE_NO_MILESTONES_INDIVIDUAL =
  'totalScoreNoMilestonesIndividual';
export const PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN = 'propertyBreakdown';
export const PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_2 = 'propertyBreakdown2';
export const PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_INDIVIDUAL =
  'propertyBreakdownIndividual';
export const PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_2_INDIVIDUAL =
  'propertyBreakdown2Individual';
export const PROJECT_CHART_TYPE__PROPERTY_FIVE_VALUES_BOX_PLOT =
  'propertyFiveValuesBoxPlot';
export const PROJECT_CHART_TYPE__PROPERTY_HISTOGRAM = 'propertyHistogram';
export const PROJECT_CHART_TYPE__PROPERTY_HISTOGRAM_INDIVIDUAL =
  'propertyHistogramIndividual';
export const PROJECT_CHART_TYPE__QUESTION_BREAKDOWN = 'questionBreakdown';
export const PROJECT_CHART_TYPE__FIVE_VALUES_BOX_PLOT = 'fiveValuesBoxPlot';
export const PROJECT_CHART_TYPE__AVERAGE_VALUE = 'averageValue';
export const PROJECT_CHART_TYPE__TOP_AVERAGE_VALUES = 'topAverageValues';
export const PROJECT_CHART_TYPE__AVERAGE_SCORE = 'averageValues';
export const PROJECT_CHART_TYPE__RADAR_AVERAGE_SCORE_AND_VARIABLE =
  'radarAverageScoreAndVariable';
export const PROJECT_CHART_TYPE__ANSWERS_SHEET_STATE_BY_DATE_SCHEDULED =
  'answersSheetStateByDateScheduled';
export const PROJECT_CHART_TYPE__ANSWERS_SHEET_PROPERTY_QUANTILES =
  'answersSheetPropertyQuantiles';

export const PROJECT_CHART_TYPE__AVERAGE_VALUE_AGAINST_MILESTONE =
  'averageValueAgainstMilestone';
export const PROJECT_CHART_TYPE__AVERAGE_VALUE_AGAINST_ANOTHER_VALUE =
  'averageValueAgainstAnotherValue';
export const PROJECT_CHART_TYPE__VALUE_CHOICES_AGAINST_MILESTONE =
  'valueChoicesAgainstMilestone';
export const PROJECT_CHART_TYPE__VALUE_CHOICES_AGAINST_ANOTHER_VALUE =
  'valueChoicesAgainstAnotherValue';
export const PROJECT_CHART_TYPE__MOST_RECENT_NUMERIC_VALUE_PIE_CHART =
  'mostRecentNumericValuePieChart';
export const PROJECT_CHART_TYPE__MOST_RECENT_VALUE_PIE_CHART =
  'mostRecentValuePieChart';

export const PROJECT_CHART_TYPES = [
  PROJECT_CHART_TYPE__TOTAL_SCORE,
  PROJECT_CHART_TYPE__TOTAL_SCORE_INDIVIDUAL,
  PROJECT_CHART_TYPE__TOTAL_SCORE_NO_MILESTONES,
  PROJECT_CHART_TYPE__TOTAL_SCORE_NO_MILESTONES_INDIVIDUAL,
  PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN,
  PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_2,
  PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_INDIVIDUAL,
  PROJECT_CHART_TYPE__PROPERTY_BREAKDOWN_2_INDIVIDUAL,
  PROJECT_CHART_TYPE__PROPERTY_HISTOGRAM,
  PROJECT_CHART_TYPE__PROPERTY_HISTOGRAM_INDIVIDUAL,
  PROJECT_CHART_TYPE__QUESTION_BREAKDOWN,
  PROJECT_CHART_TYPE__FIVE_VALUES_BOX_PLOT,
  PROJECT_CHART_TYPE__AVERAGE_VALUE,
  PROJECT_CHART_TYPE__TOP_AVERAGE_VALUES,
  PROJECT_CHART_TYPE__AVERAGE_VALUE_AGAINST_MILESTONE,
  PROJECT_CHART_TYPE__AVERAGE_VALUE_AGAINST_ANOTHER_VALUE,
  PROJECT_CHART_TYPE__VALUE_CHOICES_AGAINST_MILESTONE,
  PROJECT_CHART_TYPE__VALUE_CHOICES_AGAINST_ANOTHER_VALUE,
  PROJECT_CHART_TYPE__MOST_RECENT_NUMERIC_VALUE_PIE_CHART,
  PROJECT_CHART_TYPE__MOST_RECENT_VALUE_PIE_CHART,
  PROJECT_CHART_TYPE__TOP_AVERAGE_VALUES,
  PROJECT_CHART_TYPE__RADAR_AVERAGE_SCORE_AND_VARIABLE,
  PROJECT_CHART_TYPE__AVERAGE_SCORE,
];

export const PROJECT_CHART_TYPE_OPTIONS = [
  {
    value: PROJECT_CHART_TYPE__AVERAGE_VALUE_AGAINST_ANOTHER_VALUE,
    label: 'Average value vs selected question',
  },
  {
    value: PROJECT_CHART_TYPE__VALUE_CHOICES_AGAINST_ANOTHER_VALUE,
    label: 'Choices vs selected question',
  },
  {
    value: PROJECT_CHART_TYPE__MOST_RECENT_NUMERIC_VALUE_PIE_CHART,
    label: 'Pie chart for numeric value',
  },
  {
    value: PROJECT_CHART_TYPE__MOST_RECENT_VALUE_PIE_CHART,
    label: 'Pie chart',
  },
];

export const PROJECT_CHART_SIZE__SMALL = 'small';
export const PROJECT_CHART_SIZE__MEDIUM = 'medium';
export const PROJECT_CHART_SIZE__LARGE = 'large';
export const PROJECT_CHART_SIZE__XLARGE = 'xlarge';
export const PROJECT_CHART_SIZE__XTALL = 'xtall';

export const PROJECT_CHART_SIZES = [
  PROJECT_CHART_SIZE__SMALL,
  PROJECT_CHART_SIZE__MEDIUM,
  PROJECT_CHART_SIZE__LARGE,
  PROJECT_CHART_SIZE__XLARGE,
  PROJECT_CHART_SIZE__XTALL,
];

export const PROJECT_DASHBOARD_THEME__PRIMARY = 'primary';
export const PROJECT_DASHBOARD_THEME__ACCENT = 'accent';
export const PROJECT_DASHBOARD_THEMES = [
  PROJECT_DASHBOARD_THEME__PRIMARY,
  PROJECT_DASHBOARD_THEME__ACCENT,
];

export const PROJECT_DASHBOARD_TYPE__ACTION_ITEMS = 'actionItems';
export const PROJECT_DASHBOARD_TYPE__GENERAL = 'general';
export const PROJECT_DASHBOARD_TYPE__PATIENT = 'patient';
export const PROJECT_DASHBOARD_TYPE__CAT = 'CAT';
export const PROJECT_DASHBOARD_TYPE__NLP = 'NLP';
export const PROJECT_DASHBOARD_TYPE__CHART_BUILDER = 'chartBuilder';
export const PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME = 'externalIFrame';
export const PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE = 'milestonesTable';

export const PROJECT_DASHBOARD_TYPE__ACTIVITIES = 'activities'; // will replace action items
export const PROJECT_DASHBOARD_TYPE__ANALYTICS = 'analytics'; // will replace chart builder & general
export const PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS = 'demographics'; // will replace general
export const PROJECT_DASHBOARD_TYPE__TIMELINE = 'timeline'; // will represent current activities view
export const PROJECT_DASHBOARD_TYPE__MILESTONES = 'milestones'; // will be used for the old milestones table
export const PROJECT_DASHBOARD_TYPE__DOCUMENTS = 'documents'; // all completed answer sheets generated for the patient
export const PROJECT_DASHBOARD_TYPE__DETAILS = 'details'; // show detailed information about the entity
export const PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES = 'latestResponses'; // show latest responses for given patient and questionnaire

export const PROJECT_DASHBOARD_TYPES = [
  PROJECT_DASHBOARD_TYPE__ACTION_ITEMS,
  PROJECT_DASHBOARD_TYPE__GENERAL,
  PROJECT_DASHBOARD_TYPE__PATIENT,
  PROJECT_DASHBOARD_TYPE__CAT,
  PROJECT_DASHBOARD_TYPE__NLP,
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
  PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME,
  PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE,
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__MILESTONES,
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  PROJECT_DASHBOARD_TYPE__DETAILS,
  PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES,
];

// NOTE: "dynamic" means they're created with some additional
//       context, e.g. patientId or answersSheetId
export const PROJECT_DASHBOARD_DYNAMIC_TYPES = [
  PROJECT_DASHBOARD_TYPE__PATIENT,
  PROJECT_DASHBOARD_TYPE__CAT,
];

export const DASHBOARD_PERSPECTIVE__PATIENTS = 'patients';
export const DASHBOARD_PERSPECTIVE__RESPONSES = 'responses';
export const DASHBOARD_PERSPECTIVE__ACTIVITIES = 'activities';
export const DASHBOARD_PERSPECTIVE__UNSPECIFIED = 'unspecified';
export const DASHBOARD_PERSPECTIVES = [
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__UNSPECIFIED,
];

// NOTE: In the future we may also have other scopes, e.g.
//       "organization", which would allow showing charts data
//       from the entire database, not just one project.
export const DASHBOARD_SCOPE__PROJECT = 'project';
export const DASHBOARD_SCOPE__ENTITY = 'entity';

export const PROJECT_DASHBOARD_SUPPORTED_SCOPES = {
  [PROJECT_DASHBOARD_TYPE__ACTION_ITEMS]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__GENERAL]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__PATIENT]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__CAT]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__NLP]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
    // NOTE: We could add NLP from the perspective of a single answers sheet.
    // {
    //   perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
    //   scope: DASHBOARD_SCOPE__ENTITY,
    // },
  ],
  [PROJECT_DASHBOARD_TYPE__CHART_BUILDER]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  // NOTE: This one is not really well defined and it requires
  //       explicit configuration because scopes may vary depending
  //       the external service used to render the dashboard.
  [PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME]: [
    // NOTE I am making it a default now, but later on
    //      we will probably need to specify it at the configuration level.
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__ANALYTICS]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
    // {
    //   perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
    //   scope: DASHBOARD_SCOPE__PROJECT,
    // },
    // {
    //   perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
    //   scope: DASHBOARD_SCOPE__ENTITY,
    // },
  ],
  [PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__TIMELINE]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__MILESTONES]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__DOCUMENTS]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__DETAILS]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__ENTITY,
    },
  ],
  [PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES]: [
    {
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      scope: DASHBOARD_SCOPE__PROJECT,
    },
  ],
};
