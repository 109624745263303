import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../primitives/Box';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Spacer from '../primitives/Spacer';
import Skeleton from '../Skeleton';
import Divider from '../Divider';

// const Component = styled.div`
//   /* Add helpers e.g. https://tailwindcss.com/docs/shadows/ */
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
// `;

const Component = styled(Box.Primary)`
  overflow: hidden;

  &:empty {
    display: none;
  }
`;

const Card = ({
  space,
  children,
  loading,
}) => (
  <Component
    space={space}
    boxShadow={1}
  >
    {loading ? (
      <Stack>
        <Stack space={0}>
          <Skeleton width={200} />
          <Skeleton width="75%" />
        </Stack>
        <Skeleton
          width={275}
          height="2em"
        />
        <Divider />
        <Spacer>
          <Cluster justify="space-between">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </Cluster>
        </Spacer>
      </Stack>
    ) : (
      children
    )}
  </Component>
);

Card.propTypes = {
  space: PropTypes.number,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

Card.defaultProps = {
  space: 3,
  children: null,
  loading: false,
};

export default Card;
