// based on https://github.com/moment/luxon/blob/master/src/zones/IANAZone.js

import isNaN from 'lodash/isNaN';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

const dateTimeFormatCache = {};

function makeDTF(zone) {
  if (!dateTimeFormatCache[zone]) {
    dateTimeFormatCache[zone] = new Intl.DateTimeFormat('en-US', {
      hour12: false,
      timeZone: zone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }
  return dateTimeFormatCache[zone];
}

export const isZoneSupported = (zone) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const dtf = new Intl.DateTimeFormat('en-US', {
      timeZone: zone,
    });
    return true;
  } catch (err) {
    return false;
  }
};

const typeToIndex = {
  year: 0,
  month: 1,
  day: 2,
  hour: 3,
  minute: 4,
  second: 5,
};

const toInteger = string => parseInt(string, 10);

function hackyOffset(dateTimeFormat, date) {
  const formatted = dateTimeFormat.format(date).replace(/\u200E/g, '');
  const parsed = /(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/.exec(formatted);
  // eslint-disable-next-line array-bracket-newline
  const [, fMonth,
    fDay,
    fYear,
    fHour,
    fMinute,
    fSecond,
  ] = parsed;
  return map([
    fYear,
    fMonth,
    fDay,
    fHour,
    fMinute,
    fSecond,
  ], toInteger);
}

function partsOffset(dateTimeFormat, date) {
  const formatted = dateTimeFormat.formatToParts(date);
  const filled = [];
  for (let i = 0; i < formatted.length; i += 1) {
    const {
      type,
      value,
    } = formatted[i];
    const index = typeToIndex[type];
    if (!isUndefined(index)) {
      filled[index] = toInteger(value);
    }
  }
  return filled;
}

/**
 * Given a IANA zone name, returns a function that maps timestamps to UTC offsets represented in minutes.
 * @param {string} zone
 * @returns {(ts: Date | number) => number}
 */
export const zoneToUtcOffset = (zone) => {
  let dateTimeFormat;
  try {
    dateTimeFormat = makeDTF(zone);
  } catch (err) {
    return () => NaN;
  }
  return (ts) => {
    const date = ts !== undefined ? new Date(ts) : new Date();
    if (isNaN(date.getTime())) {
      return NaN;
    }
    const [
      fYear,
      fMonth,
      fDay,
      fHour,
      fMinute,
      fSecond,
    ] = dateTimeFormat.formatToParts
      ? partsOffset(dateTimeFormat, date)
      : hackyOffset(dateTimeFormat, date);
    const asUTC = Date.UTC(fYear, fMonth - 1, fDay, fHour, fMinute, fSecond);
    let asTS = date.valueOf();
    asTS -= asTS % 1000;
    return (asUTC - asTS) / (60 * 1000);
  };
};

/**
 * Given a IANA zone name, returns a function that maps UTC time (expressed as a timestamp)
 * to a timestamp representing the same time of the day but in the given timezone.
 * If "no strict" mode is used, that function will always return a value even if
 * the exact time cannot be found.
 * @param {string} zone
 * @param {object} [options]
 * @param {boolean} [options.noStrict]
 * @returns {(ts: Date | number) => number}
 */
export const createUtcToLocalTime = (zone, {
  noStrict,
} = {}) => {
  const getUtcOffset = zoneToUtcOffset(zone);
  return (utcDateOrTs) => {
    // we want the following constraints to be satisfied:
    // ts + offset * 60000 === asUTC
    // getUtcOffset(ts) === offset
    const asUTC = new Date(utcDateOrTs).valueOf();
    let nextOffset = getUtcOffset(asUTC);
    let ts;
    let offset;
    let iterations = 0;
    do {
      // if there's no consensus after a couple of iterations, then it probably means that the given time does not
      // even exist in that timezone, e.g. when clock changes from 01:59 to 3:00 because of DST
      if (iterations > 10) {
        return noStrict ? ts : null;
      }
      offset = nextOffset;
      ts = asUTC - 60000 * offset;
      nextOffset = getUtcOffset(ts);
      iterations += 1;
    } while (!isNaN(nextOffset) && !isNaN(offset) && nextOffset !== offset);
    return ts;
  };
};

export function getTimezone() {
  if (typeof Intl !== 'undefined') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return undefined;
}
