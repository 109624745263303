import {
  createSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import User from '../models/User';
import ProjectSelect from './Project';

const constant = x => () => x;

const Select = createSelectors(User, {
  whenMemberOfProject: getProjectId => select => select.where(
    createSelector(
      ProjectSelect.one().whereIdEquals(getProjectId),
      (project) => {
        if (!project) {
          return constant(false);
        }
        return user => user.isMemberOf(project.getDomains());
      },
    ),
  ),
});

Select.getForFormulaScope = Select.all()
  .map(constant(user => user.getFormulaScopeProperties()))
  .byId();

export default Select;
