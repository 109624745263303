import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import some from 'lodash/some';
import get from 'lodash/get';
import compact from 'lodash/compact';
import find from 'lodash/find';
import has from 'lodash/has';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Role from './Role';
import {
  PERMISSIONS_DOMAIN_DELIMITER,
  PROPERTY_TYPE__STRING,
  USER_PROPERTY__NAME,
  USER_PROPERTY__JOB_TITLE,
} from '../constants';
import {
  isPrefix,
} from '../utils/text';
import BaseModel from './BaseModel';
import PermissionsDomain from './PermissionsDomain';

const constant = x => () => x;

const validateDomain = (domain) => {
  if (domain === '') {
    return true;
  }
  if (typeof domain !== 'string') {
    return false;
  }
  return domain.charAt(domain.length - 1) === PERMISSIONS_DOMAIN_DELIMITER;
};

const getGrant = (key, permissions) => {
  if (!has(permissions, key)) {
    return null;
  }
  return {
    tier: permissions[key],
  };
};

/**
 * Represents a User.
 * @class
 */
class User extends BaseModel {
  getEmailAddress() {
    return this.emails && this.emails[0] && this.emails[0].address;
  }

  getPhoneNumber() {
    return this.phones && this.phones[0] && this.phones[0].number;
  }

  getFullName() {
    if (this.name) {
      return this.name;
    }
    if (!this.title && !this.firstName && !this.lastName) return '';
    return compact([
      this.title,
      this.firstName,
      this.lastName,
    ]).join(' ');
  }

  getJobTitle() {
    return this.jobTitle;
  }

  /**
   * Return roles that applies exactly to the given domain.
   * @param {String} domain
   * @returns {Role[]}
   */
  getRolesInDomain(domain) {
    return this.roles
      ? this.roles.filter(role => role.appliesTo === domain)
      : [];
  }

  /**
   * Return roles that applies to domains different than the given one.
   * @param {String} domain
   * @returns {Role[]}
   */
  getRolesNotInDomain(domain) {
    return this.roles
      ? this.roles.filter(role => role.appliesTo !== domain)
      : [];
  }

  getRolesIdsInDomain(domain) {
    if (!domain) return [];
    return map(this.getRolesInDomain(domain), 'id');
  }

  getRolesIdsNotInDomain(domain) {
    if (!domain) return [];
    return map(this.getRolesNotInDomain(domain), 'id');
  }

  getRolesNamesInDomain(domain) {
    return map(this.getRolesInDomain(domain), 'name');
  }

  getRolesNamesNotInDomain(domain) {
    return map(this.getRolesNotInDomain(domain), 'name');
  }

  getRolesGrantedBy(userId) {
    return filter(this.roles, {
      grantedBy: userId,
    });
  }

  getRolesIdsGrantedBy(userId) {
    return map(this.getRolesGrantedBy(userId), 'id');
  }

  getRolesNamesGrantedBy(userId) {
    return map(this.getRolesGrantedBy(userId), 'name');
  }

  isMemberOf(domainOrDomains, groupType) {
    return !!find(this.groups, (group) => {
      if (groupType && group.type !== groupType) {
        return false;
      }
      if (isArray(domainOrDomains)) {
        return includes(domainOrDomains, group.appliesTo);
      }
      if (typeof domainOrDomains === 'string') {
        return group.appliesTo === domainOrDomains;
      }
      return false;
    });
  }

  /**
   * Return a list of all roles that appliesTo to "relativeTo" domain.
   * If relativeTo is provided, it must be a full domain path
   * (so in particular it must end with PATH_DELIMITER)
   * or and array of domain paths. If "relativeTo"
   * is not specified return all roles.
   */
  getRoles({
    relativeTo = '',
  } = {}) {
    let allowedDomains;
    if (isArray(relativeTo)) {
      allowedDomains = filter(relativeTo, validateDomain);
    } else if (!validateDomain(relativeTo)) {
      return [];
    }
    if (relativeTo === '') {
      return this.roles || [];
    }
    const roles = filter(this.roles, (role) => {
      if (!role || !role.appliesTo) {
        return false;
      }
      const predicate = isPrefix(role.appliesTo, PERMISSIONS_DOMAIN_DELIMITER);
      if (allowedDomains) {
        return some(allowedDomains, predicate);
      }
      return predicate(relativeTo);
    });
    return roles;
  }

  /**
   * Return a list of all roles that appliesTo to domain
   * or it's sub-domain; this is useful for filtering roles
   * that are relevant to a specific organization.
   */
  getRolesWithinDomain(domain) {
    if (!validateDomain(domain)) {
      return [];
    }
    if (domain === '') {
      return this.roles || [];
    }
    const predicate = isPrefix(domain, PERMISSIONS_DOMAIN_DELIMITER);
    const roles = filter(this.roles, (role) => {
      if (!role || !role.appliesTo) {
        return false;
      }
      return role.appliesTo === domain || predicate(role.appliesTo);
    });
    return roles;
  }

  getRolesIds(options) {
    return map(this.getRoles(options), 'id');
  }

  getRolesNames(options) {
    return map(this.getRoles(options), 'name');
  }

  hasRole(roleId) {
    return !!find(this.roles, ({
      id,
    }) => id === roleId);
  }

  getRolesTags(options) {
    const selected = {};
    this.getRoles(options).forEach(({
      tags,
    }) => tags.forEach(({
      name,
    }) => {
      selected[name] = true;
    }));
    return Object.keys(selected);
  }

  hasRoleTag(tag, options) {
    const roles = this.getRoles(options);
    for (let i = 0; i < roles.length; i += 1) {
      const {
        tagsNames,
      } = roles[i];
      if (tagsNames && tagsNames.some(name => name === tag)) {
        return true;
      }
    }
    return false;
  }

  getGroups({
    inAnyOf,
    type,
  } = {}) {
    let domains;
    if (isArray(inAnyOf)) {
      domains = filter(inAnyOf, validateDomain);
    }
    const predicate = domains
      ? (() => {
        const predicates = map(domains, domain => isPrefix(domain, PERMISSIONS_DOMAIN_DELIMITER));
        return domain => some(predicates, p => p(domain));
      })()
      : constant(true);
    if (type) {
      return filter(
        this.groups,
        group => group.type === type && predicate(group.appliesTo),
      );
    }
    return filter(this.groups, group => predicate(group.appliesTo));
  }

  getGroupsIds(options) {
    return map(this.getGroups(options), 'id');
  }

  getGroupsInDomain(domain) {
    return filter(this.groups, group => group.appliesTo === domain);
  }

  getGroupsIdsInDomain(domain) {
    return map(this.getGroupsInDomain(domain), 'id');
  }

  getGroupsNamesInDomain(domain) {
    return map(this.getGroupsInDomain(domain), 'name');
  }

  getGroupsNames(options) {
    return map(this.getGroups(options), 'name');
  }

  formatRecentActivity() {
    if (!this.recentAction) return '';
    return moment(this.recentAction.time).calendar(null, {
      sameElse: 'DD/MM/YYYY',
    });
  }

  formatCreatedAt() {
    return !this.createdAt ? '' : moment(this.createdAt).format('DD/MM/YYYY');
  }

  getFailedLoginAttempts() {
    return this.failedLoginAttempts || 0;
  }

  isLocked() {
    return !!this.locked;
  }

  isAzure() {
    return (
      !isEmpty(this.integrationId) &&
      get(this, 'integrationSource') === 'OAuth/azure'
    );
  }

  isRemoved() {
    return isEmpty(this.roles) && isEmpty(this.groups);
  }

  /**
   * Evaluates sum of all permissions relative to the given domain.
   * If "relativeTo" is not provided, then permissions from all roles are returned.
   * @param {Object} options
   * @param {String} options.relativeTo
   * @param {Object} options.rolesDB
   * @param {String} options.scope
   * @returns {Object}
   */
  getAllPermissions({
    relativeTo = '',
    rolesDB,
    scope,
  } = {}) {
    return Role.extractFlatPermissions({
      rolesIds: this.getRolesIds({
        relativeTo,
      }),
      rolesDB,
      scope,
    });
  }

  getPermissionsValidator(options) {
    const permissions = this.getAllPermissions(options);
    const validateOne = (requested) => {
      if (requested && typeof requested === 'string') {
        return getGrant(requested, permissions);
      }
      if (
        requested &&
        typeof requested === 'object' &&
        typeof requested.key === 'string'
      ) {
        const granted = getGrant(requested.key, permissions);
        if (has(requested, 'tier')) {
          if (typeof requested.tier !== 'number') {
            this.constructor.logger
              .warn(`Permission tier was specified for ${requested.key}
  but invalid value was provided: ${requested.tier}. Expected number.`);
            return null;
          }
          if (!granted || granted.tier < requested.tier) {
            return null;
          }
        }
        return granted;
      }
      return null;
    };
    return (condition) => {
      if (isArray(condition)) {
        let tier = Infinity;
        // The resulting tier will be the minimum of all granted tiers.
        for (let i = 0; i < condition.length; i += 1) {
          const c = condition[i];
          const granted = validateOne(c);
          if (!granted) {
            return null;
          }
          tier = Math.min(granted.tier, tier);
        }
        return {
          tier,
        };
      }
      return validateOne(condition);
    };
  }

  /**
   * Return all domains (realm) at where the user has all the given primitive permission.
   * @param {string} permission
   */
  getDomainsWithPrimitivePermission(
    permission,
    {
      tier,
      simplify = true,
      rolesDB,
    } = {},
  ) {
    const domains = [];
    if (this.roles) {
      this.roles.forEach((role) => {
        if (
          Role.roleHasPermission({
            permission,
            tier,
            roleId: role.id,
            rolesDB,
          })
        ) {
          domains.push(role.appliesTo);
        }
      });
    }
    if (simplify) {
      return PermissionsDomain.extractFundamentalDomains(domains);
    }
    return domains;
  }

  /**
   * Return all domains (realm) at where the user has all the given permissions.
   * @param {string|string[]} permissions - can be a string (for one permission) or an array of strings
   */
  getPermissionsRealm(permissions, options) {
    const {
      rolesDB,
    } = options || {};
    const realms = [];
    if (!isArray(permissions)) {
      return this.getPermissionsRealm([
        permissions,
      ], options);
    }
    permissions.forEach((permission) => {
      let permissionKey;
      let tier;
      if (typeof permission === 'string') {
        permissionKey = permission;
      } else if (
        typeof permission === 'object' &&
        typeof permission.key === 'string'
      ) {
        permissionKey = permission.key;
        if (typeof permission.tier === 'number') {
          ({
            tier,
          } = permission);
        }
      } else {
        permissionKey = null;
      }
      if (permissionKey) {
        realms.push(
          this.getDomainsWithPrimitivePermission(permissionKey, {
            tier,
            simplify: false,
            rolesDB,
          }),
        );
      }
    });
    return PermissionsDomain.findCommonRealm(realms);
  }

  getPermissionsRealmSelectors(field, permissions) {
    return this.getPermissionsRealm(permissions).map(domain => ({
      [field]: {
        $regex: `^${domain}`,
      },
    }));
  }

  getTopLevelDomains() {
    return PermissionsDomain.extractFundamentalDomains(
      map(this.groups, 'appliesTo'),
    );
  }

  hasPermission(key, options) {
    const permissions = this.getAllPermissions(options);
    if (isArray(key)) {
      return key.every(k => !!getGrant(k, permissions));
    }
    return !!getGrant(key, permissions);
  }

  getReference() {
    return {
      id: this._id,
      name: this.getFullName(),
      email: this.getEmailAddress(),
      phone: this.getPhoneNumber(),
    };
  }

  static selectIfHasRoleInDomain(domain) {
    return {
      'roles.appliesTo': domain,
    };
  }
}

User.properties = {
  [USER_PROPERTY__NAME]: {
    key: 'name',
    type: PROPERTY_TYPE__STRING,
  },
  [USER_PROPERTY__JOB_TITLE]: {
    key: 'jobTitle',
    type: PROPERTY_TYPE__STRING,
  },
};

User.collection = 'users';

export default User;
