import {
  css,
} from 'styled-components';
import {
  theme,
} from '../../utilsClient/cssHelpers';

export const reset = css`
  background: none;
  border: none;
  outline: none;
  padding: 0;
`;

export const types = {
  default: css`
    background-color: ${theme('color.surface')};
    border-color: ${theme('color.border')};
    color: ${theme('color.onSurface')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  primary: css`
    background-color: ${theme('color.primary')};
    color: ${theme('color.onPrimary')};

    &:hover {
      background-color: ${theme('color.primaryHover')};
    }

    &:active {
      background-color: ${theme('color.primaryActive')};
    }
  `,
  link: css`
    border-color: transparent !important;
    color: ${theme('color.primary')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  danger: css`
    background-color: ${theme('color.danger')};
    color: ${theme('color.onPrimary')};

    &:hover {
      background-color: ${theme('color.dangerHover')};
    }

    &:active {
      background-color: ${theme('color.dangerActive')};
    }
  `,
  cta: css`
    background-color: ${theme('color.primary')};
    border-radius: 20px;
    color: ${theme('color.onPrimary')};

    &:hover {
      background-color: ${theme('color.primaryHover')};
    }

    &:active {
      background-color: ${theme('color.primaryActive')};
    }
  `,
};

export const sizes = {
  small: css`
    height: 1.5rem;
  `,
  default: css`
    height: 2rem;
  `,
  large: css`
    height: 2.5rem;
  `,
};

const iconOnlySizes = {
  small: css`
    width: 1.5rem;
  `,
  default: css`
    width: 2rem;
  `,
  large: css`
    width: 2.5rem;

    svg {
      height: 100%;
      width: 100%;
      padding: 0.675em;
    }
  `,
};

const iconOnlyTypes = {
  default: css`
    color: ${theme('color.onBackground')};
  `,
};

export const ghost = {
  default: css`
    background: none;
    border: none;
  `,
  primary: css`
    background: none;
    color: ${theme('color.primary')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
  danger: css`
    background: none;
    color: ${theme('color.danger')};

    &:hover {
      background-color: ${theme('color.hover')};
    }

    &:active {
      background-color: ${theme('color.active')};
    }
  `,
};

export const disabled = css`
  background-color: ${theme('color.background')};
  border-color: ${theme('color.border')};
  color: ${theme('color.disabled')};
  cursor: not-allowed;

  &::before,
  &::after {
    content: none;
  }
`;

export const loading = css`
  background-color: ${theme('color.background')};
  border-color: ${theme('color.border')};
  color: ${theme('color.disabled')};
  cursor: default;

  /* &::before {
    background-color: rgba(255,255,255,0.1);
  } */

  &::before,
  &::after {
    content: none;
  }
`;

export const block = css`
  width: 100%;
`;

export const iconOnly = css`
  padding: 0;

  ${props => iconOnlySizes[props.size]};
  ${props => iconOnlyTypes[props.stylesType]};
`;

export const defaultStyles = css`
  cursor: pointer;
  display: block;
  position: relative;
  /* border: 0; */
  border: 1px solid hsla(${theme('color.darkBase')}, 0.1);
  transition: 150ms ease-in-out;

  &:hover {
    transform: translate3d(0px, -2px, 0px);
  }

  &:active {
    transform: translate3d(0, 0px, 0);
  }

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(1.05) scaleY(1.2);
  }
`;
