import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  reset,
} from './styles';

const Component = styled.button`
  ${reset};

  cursor: pointer;
  color: ${theme('color.primary')};
  transition: color ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};

  &:hover,
  &:focus {
    color: ${theme('color.primaryHover')};
    text-decoration: underline;
  }
`;

const ButtonLink = ({
  children,
  onClick,
}) => {
  return <Component onClick={onClick}>{children}</Component>;
};

ButtonLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ButtonLink.defaultProps = {
  children: null,
  onClick: () => {},
};

export default ButtonLink;
