import forEach from 'lodash/forEach';
import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import {
  QUESTION_TYPE__COLLECTION,
} from '../constants';
import {
  collapse,
} from './formValues';

const nonStandardAnswerProps = [
  'other',
  'text1',
  'text2',
];

const allowedAnswerProps = [
  'value',
  ...nonStandardAnswerProps,
];

/**
 * Returns a unique key for the given response.
 * @private
 * @param {Object} response
 * @returns {String}
 */
export const getKey = response => (response.hierarchyKey
  ? `${response.hierarchyKey}.${response.questionId}`
  : response.questionId);

const identity = x => x;
const constant = x => () => x;

export function toResponsesMap(formValues, isCollection = false) {
  const responsesMap = {};
  const assign = (element, elementId, questionId) => {
    if (!element) {
      return responsesMap;
    }
    if (element._elements || element._elementsOrder) {
      forEach(
        toResponsesMap(element._elements, !!element._elementsOrder),
        (response, dataKey) => {
          responsesMap[`${elementId}.${dataKey}`] = {
            ...response,
            hierarchyKey: response.hierarchyKey
              ? `${elementId}.${response.hierarchyKey}`
              : `${elementId}`,
          };
          if (!response.questionId) {
            responsesMap[`${elementId}.${dataKey}`].questionId = '$';
            responsesMap[
              `${elementId}.${dataKey}`
            ].hierarchyKey = `${elementId}.${dataKey}`;
          }
        },
      );
      if (element._elementsOrder) {
        responsesMap[elementId] = {
          answer: {
            value: element._elementsOrder,
          },
        };
      }
    } else {
      responsesMap[elementId] = {
        answer: pick(element, allowedAnswerProps),
      };
    }
    if (questionId && responsesMap[elementId]) {
      responsesMap[elementId].questionId = questionId;
    }
    return responsesMap;
  };

  if (isCollection) {
    forEach(formValues, (element, id) => {
      // NOTE: We are passing null as questionId, because the identifier
      //       does not represent a question in case of "array".
      assign(element, id, null);
    });
  } else {
    forEach(formValues, (element, id) => {
      assign(element, id, id);
    });
  }

  return responsesMap;
}

/**
 * Object representing a single questionnaire response.
 * @typedef {Object<string, any>} Response
 * @property {Object<string, any>} answer
 * @property {string} questionId
 * @property {string} hierarchyKey
 */

/**
 * Evaluate responses "diff" based on current and original form values.
 * @param {Object<string, any>} newFormValues
 * @param {Object<string, any>} previousFormValues
 * @return {Object<string, Response>[]}
 */
export function toResponsesArray(newFormValues, previousFormValues = {}) {
  const newResponsesMap = toResponsesMap(newFormValues);
  const originalResponsesMap = toResponsesMap(previousFormValues);
  const newResponses = [];
  forEach(newResponsesMap, (newResponse, key) => {
    const originalAnswer =
      originalResponsesMap[key] && originalResponsesMap[key].answer;
    const newAnswer = newResponse && newResponse.answer;
    if (!isEqual(originalAnswer, newAnswer)) {
      newResponses.push(
        omitBy(
          {
            questionId: newResponse.questionId,
            answer: newResponse.answer,
            hierarchyKey: newResponse.hierarchyKey,
          },
          isNil,
        ),
      );
    }
  });
  return newResponses;
}

const defaultPickValues = (response) => {
  if (response.questionType === QUESTION_TYPE__COLLECTION) {
    return null;
  }
  const {
    answer,
  } = response;
  const values = pick(answer, nonStandardAnswerProps);
  if (answer && answer.value !== undefined) {
    values.value = answer.value;
  }
  return values;
};

/**
 * Convert responses array to a hierarchical form values object that
 * can be used with redux-form to represent form state.
 * @param {Object[]} responses
 * @param {Object} [options]
 * @param {Boolean} [options.collapsed]
 * @param {Boolean} [options.includeMetaAnswer] - will attach _meta field to every answer object, with data taken from response.meta
 * @returns {Object} formValues object
 */
export function toFormValues(
  responses,
  {
    collapsed = false,
    includeMetaAnswer = false,
    includeNullAnswer = false,
    pickValues = defaultPickValues,
  } = {},
) {
  const formValues = {
    _elements: {},
  };

  const assign = (object = {}, parts, values) => {
    if (parts.length === 0) {
      Object.assign(object, values);
    } else {
      if (!object._elements) {
        // eslint-disable-next-line no-param-reassign
        object._elements = {};
      }
      // eslint-disable-next-line no-param-reassign
      object._elements[parts[0]] = assign(
        object._elements[parts[0]],
        parts.slice(1),
        values,
      );
    }
    return object;
  };

  forEach(responses, (response, index) => {
    if (!response || (!response.answer && !includeNullAnswer)) {
      return;
    }
    const parts = response.hierarchyKey ? response.hierarchyKey.split('.') : [];
    if (response.questionId !== '$') {
      parts.push(response.questionId);
    }
    const values = {};
    switch (response.questionType) {
      case QUESTION_TYPE__COLLECTION:
        values._elementsOrder = response.answer && response.answer.value;
        break;
      default: {
        // ...
      }
    }
    const newValues = pickValues && pickValues(response, index);
    if (!isEmpty(newValues)) {
      Object.assign(values, newValues);
    }
    if (includeMetaAnswer && response.meta) {
      // eslint-disable-next-line no-underscore-dangle
      values._meta = response.meta;
    }
    assign(formValues, parts, values);
  });

  if (collapsed) {
    return collapse(formValues);
  }

  return formValues._elements;
}

export function filterResponses(
  responses,
  {
    predicate = constant(true),
    transform = identity,
  } = {},
) {
  const acceptableResponses = filter(
    responses,
    response => response && response.questionId,
  );
  const responsesByKey = keyBy(acceptableResponses, getKey);
  const validResponses = {};
  const getParentResponse = (response) => {
    if (!response.hierarchyKey) {
      return undefined;
    }
    const parts = response.hierarchyKey.split('.');
    while (parts.length > 0) {
      const hierarchyKey = parts.join('.');
      if (responsesByKey[hierarchyKey]) {
        return responsesByKey[hierarchyKey];
      }
      parts.pop();
    }
    return undefined;
  };
  const isValidResponse = (response) => {
    const key = getKey(response);
    if (validResponses[key] !== undefined) {
      return validResponses[key];
    }
    if (!response.hierarchyKey) {
      validResponses[key] = predicate(response);
    } else {
      const parentResponse = getParentResponse(response);
      if (!parentResponse) {
        validResponses[key] = false;
      } else {
        // NOTE: By the definition of getParentResponse, parentKey
        //       is at most equal to response.hierarchyKey, and
        //       becase the latter is always shorter than getKey(response)
        //       there's no risk of infinite recursion.
        const parentKey = getKey(parentResponse);
        if (parentKey === response.hierarchyKey) {
          validResponses[key] = !!(
            predicate(response) && isValidResponse(parentResponse)
          ); // NOTE: Recursive call!
        } else {
          const elementId = response.hierarchyKey.substr(parentKey.length + 1);
          validResponses[key] = !!(
            predicate(response) &&
            isValidResponse(parentResponse) && // NOTE: Recursive call!
            parentResponse.answer &&
            isArray(parentResponse.answer.value) &&
            parentResponse.answer.value.indexOf(elementId) >= 0
          );
        }
      }
    }
    return validResponses[key];
  };
  const filteredAndTransformedResponses = [];
  forEach(acceptableResponses, (response) => {
    if (isValidResponse(response)) {
      filteredAndTransformedResponses.push(transform(response));
    }
  });
  return filteredAndTransformedResponses;
}
