import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Check,
  Close,
} from 'styled-icons/material';
import {
  LockOutlined,
} from '@ant-design/icons';
import Divider from '../../common/components/Divider';
import Spinner from '../../common/components/Spinner';
import Text from '../../common/components/base/Text';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Spacer from '../../common/components/primitives/Spacer';
import useQuestionnaire from '../../utils/useQuestionnaire';
import {
  STATE_READY,
  STATE_ACTIVE,
  STATE_ERROR,
} from '../../store/stage';
import {
  theme,
} from '../../common/utilsClient/cssHelpers';

const StyledCheck = styled(Check)`
  color: ${theme('color.success')};
`;

const getIcon = (state, draftState) => {
  if (state === 'COMPLETED' || draftState === STATE_READY) {
    return <StyledCheck size={24} />;
  }
  if (draftState === STATE_ACTIVE) {
    return <Spinner />;
  }
  if (draftState === STATE_ERROR) {
    return <Close size={24} />;
  }
  return <LockOutlined />;
};

const ListItem = ({
  label,
  icon,
}) => (
  <Cluster justify="space-between">
    <Text.Span
      size="small"
      importance="low"
    >
      {label}
    </Text.Span>
    <Text.Span
      size="small"
      importance="low"
    >
      {icon}
    </Text.Span>
  </Cluster>
);

const CurrentListItem = ({
  label,
  completionRate,
}) => (
  <Cluster justify="space-between">
    <Text.Span importance="high">{label}</Text.Span>
    <Text.Span importance="high">
      {completionRate}
      {'%'}
    </Text.Span>
  </Cluster>
);

const AnswersSheetsList = ({
  answersSheets,
  currentAnswersSheetId,
}) => {
  const {
    mostRecentDraft,
  } = useQuestionnaire(currentAnswersSheetId);
  const completionRate = mostRecentDraft.completionRate || 0;
  return (
    <Spacer>
      <Stack space={2}>
        {answersSheets.map(({
          id,
          state,
          draftState,
          questionnaire,
        }) => {
          const isCurrent = id === currentAnswersSheetId;

          return (
            <Stack
              key={id}
              space={2}
            >
              {' '}
              <Divider />
              <Spacer space={2}>
                {isCurrent ? (
                  <CurrentListItem
                    label={questionnaire && questionnaire.name}
                    completionRate={completionRate}
                  />
                ) : (
                  <ListItem
                    label={questionnaire && questionnaire.name}
                    icon={getIcon(state, draftState)}
                  />
                )}
              </Spacer>
            </Stack>
          );
        })}
      </Stack>
    </Spacer>
  );
};

ListItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
};

ListItem.defaultProps = {
  label: null,
  icon: null,
};

CurrentListItem.propTypes = {
  label: PropTypes.string,
  completionRate: PropTypes.number,
};

CurrentListItem.defaultProps = {
  label: null,
  completionRate: null,
};

AnswersSheetsList.propTypes = {
  currentAnswersSheetId: PropTypes.string,
  answersSheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      state: PropTypes.string,
      draftState: PropTypes.string,
      questionnaire: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
};

AnswersSheetsList.defaultProps = {
  currentAnswersSheetId: null,
  answersSheets: [],
};

export default AnswersSheetsList;
